// React imports

// Remix imports

// Third party imports

// Generated imports
import type { Maybe } from '~/generated/graphql'
import useGlobal from '~/hooks/useGlobal'

// App imports

export const SQUARE_COVER = '?tr=w-255,h-255,bg-FFFFFF'
export const SQUARE_FILL_GRAY =
  '?tr=cm-pad_resize,w-255,h-255,e-grayscale,bg-FFFFFF'

const useImagekit = () => {
  const global = useGlobal()

  if (!global) {
    return {
      imagekitUrl: (src?: string | Maybe<string>, settings?: string) => '',
    }
  }

  const imagekitUrl = (src?: string | Maybe<string>, settings?: string) => {
    /**
     * Takes an image path, which should have the form
     * `appname/ModelName/name.jpg` and returns the full URL for imagekit.
     *
     */
    if (!src) {
      return ''
    }
    if (src.startsWith('http')) {
      // for backwards compatibility. We might have some resolvers that still
      // return full image paths
      return src
    }
    let result = `${global.ENV.IMAGEKIT_PATH}${src}`
    if (settings) {
      result += settings
    }

    return result
  }

  return { imagekitUrl }
}

export default useImagekit
